<template>
	<div class="shopping-cart">
		<PopoverMenu
			:min-width="565"
			ref="shoppingCart"
		>
			<template #trigger>
				<div class="icon">
					<TnIcon
						name="cart"
						size="l"
					/>
					<div
						class="bubble"
						v-if="visibleCartItems && visibleCartItems.length > 0"
						:class="{ visible: visibleCartItems.length > 0, dark: dark }"
					>
						{{ totalItems }}
					</div>
				</div>
			</template>
			<template #menu>
				<div v-if="visibleCartItems && visibleCartItems.length > 0">
					<ShoppingCartSummary />
					<div class="checkout">
						<TnButton
							v-if="!isInCheckout"
							text="Gå til bestilling"
							:to="flowPath"
							block
							:size="isMobile ? 's' : 'm'"
							@click="closeCart"
						/>
						<TnButton
							v-if="!swapPhoneExist && isMobile"
							text="Fortsett å handle"
							secondary
							block
							size="s"
							@click="toggleCart"
						/>
					</div>
				</div>
				<div
					class="empty-cart"
					v-else
				>
					<h2 class="hidden-desktop mobile-header">Din handlekurv</h2>
					Handlekurven din er tom
				</div>
			</template>
		</PopoverMenu>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mapState as mapPiniaState } from "pinia";

export default defineNuxtComponent({
	name: "ShoppingCart",
	props: ["menu-hidden", "dark"],

	computed: {
		...mapState(["siteSegment", "isTest"]),
		...mapState("shoppingCart", ["open", "emptied"]),
		...mapPiniaState(useUserStore, ["customer"]),
		...mapGetters("shoppingCart", ["visibleCartItems"]),
		...mapGetters(["isMobile"]),
		isMobile() {
			const mobileBreakpoint = 768;
			return window?.innerWidth < mobileBreakpoint;
		},
		totalItems() {
			return this.visibleCartItems
				?.filter((item) => !item?.metadata?.processed && item.type !== "msisdn")
				?.reduce(
					(acc, curr) => [...acc, curr, ...(curr?.subItems?.filter((subItem) => subItem.type !== "msisdn") || [])],
					[],
				)
				?.flat()?.length;
		},
		flowPath() {
			return this.onlySwapPhone ? "/mobiltelefoner/innbytte/bestill/" : "/checkout/auth.page";
		},

		isInCheckout() {
			return this.$route.path.includes("/checkout/");
		},

		onlySwapPhone() {
			return this.visibleCartItems.length === 1 && this.visibleCartItems[0].type === "old-swap-device";
		},

		swapPhoneExist() {
			return this.visibleCartItems.some((it) => it.type === "old-swap-device");
		},
	},

	methods: {
		toggleCart(event) {
			if (event) event.preventDefault();
			this.$store.commit("shoppingCart/toggle");
			this.$refs.shoppingCart.toggleMenu();
		},
		openCart() {
			// Store commit not working to open cart
			this.$store.commit("shoppingCart/open");
		},
		closeCart() {
			this.$store.commit("shoppingCart/close");
		},
		remove(itemId) {
			this.$store.commit("shoppingCart/remove", itemId);
		},
		async addToCart(item) {
			return this.$store.dispatch("shoppingCart/add", item);
		},
		async addSwapPendingToCart(item) {
			await this.$store.dispatch("shoppingCart/emptyCart");

			return this.$store.dispatch("shoppingCart/addSwapPendingDevice", item);
		},
		async init() {
			return this.$store.dispatch("shoppingCart/init");
		},
	},

	created() {
		if (import.meta.client) {
			// Allows the shopping cart to be manipulated outside of Vue space, e.g. the chat bot
			window.shoppingCart = {
				add: this.addToCart,
				toggle: this.toggleCart,
				open: this.openCart,
				close: this.closeCart,
				addSwapPending: this.addSwapPendingToCart,
			};
		}
	},

	async mounted() {
		this.$store.dispatch("shoppingCart/init");
	},

	watch: {
		menuHidden(oldState, newState) {
			if (this.open && !newState) this.$store.commit("shoppingCart/close");
		},
		emptied(value) {
			if (value && this.$route.path.includes("/checkout/")) {
				window.location.href = "/mobiltelefoner";
			}
		},
		$route() {
			this.$store.commit("shoppingCart/close");
		},
	},
});
</script>

<style lang="scss" scoped>
@keyframes pop {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1.3);
	}

	100% {
		transform: scale(1);
	}
}

.empty-cart {
	text-align: center;
	color: $color-text;

	@include font-text-bold-m;
}

.hide-menu .shopping-cart .cart-items {
	@include breakpoint(mobile) {
		top: 60px;
	}
}

.shopping-cart {
	display: inline-block;
	position: relative;

	.icon {
		position: relative;
		line-height: 0;

		.bubble {
			position: absolute;
			top: -1px;
			right: -2px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background: $color-information-500-core;
			width: 14px;
			height: 14px;
			box-shadow: 0 0 0 2px $color-neutrals-white;
			color: $color-neutrals-white;
			animation: pop 0.3s ease-out;

			@include font-text-bold-2xs;

			&.dark {
				box-shadow: 0 0 0 2px $color-primary-dark;
				background: $color-information-200-tint;
				color: $color-primary-dark;
			}
		}
	}

	.total {
		display: flex;
		min-height: 55px;
		padding: $spacing-l $spacing-m;
		justify-content: flex-end;
		font-weight: bold;
		text-align: right;
		border-top: 1px solid $color-neutrals-200-tint;

		h4 {
			margin-bottom: $spacing-m;
		}

		.price {
			@include font-text-l;
		}
	}

	.trigger {
		display: inline-flex;
		align-items: center;
		color: inherit;
		text-decoration: none;
		padding-left: $spacing-m;
		padding-right: $spacing-s;

		span {
			margin-right: $spacing-s;

			@include breakpoint(mobile) {
				display: none;
			}
		}

		@include breakpoint(mobile) {
			padding-right: 0;
			padding-left: 0;
		}
	}

	.mobile-header {
		display: flex;
		align-items: center;
		padding: 0 $spacing-s $spacing-l;
		margin-top: -$spacing-l;
		margin-bottom: $spacing-l;
		justify-content: space-between;

		@include font-text-xl;

		svg {
			width: 18px;
			height: 18px;
		}
	}

	.cart-items {
		:deep(.table-wrap) {
			max-height: calc(100vh - 345px);
			overflow: auto;
		}

		@include breakpoint(mobile) {
			:deep(.table-wrap) {
				height: calc(100% - 300px);
				overflow: auto;
			}
		}
	}

	@include breakpoint(mobile) {
		position: static;
	}
}

.checkout {
	position: sticky;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: $spacing-m;
	bottom: 0;
	padding: $spacing-xl 0;
	margin-left: -$spacing-m;
	margin-right: -$spacing-m;
	border-radius: 0 $spacing-m $spacing-m 0;
	background-color: white;
	transform: translateY(25px);

	a,
	button {
		max-width: 80%;
	}
}
</style>
