<template>
	<ECommerceHardwareProductCard
		v-for="product in cardItems"
		:key="product.id"
		:product="product"
		:banner-text="bannerText"
	/>
	<ECommerceCardWrapper
		v-if="subscriptionItems"
		class="shopping-cart-card"
		padding="16px"
		staticComponent
	>
		<div class="shopping-cart-card-main">
			<ShoppingCartCardSubscriptionItem :subscriptionItems="subscriptionItems" />
		</div>

		<template v-if="bannerText">
			<div class="shopping-cart-card-banner">
				<h3>{{ bannerText }}</h3>
			</div>
		</template>
	</ECommerceCardWrapper>
</template>

<script setup lang="ts">
import { useStore } from "vuex";
import { formatPrice } from "~/helpers/formatting/price";

const { cardItems, subscriptionItems } = defineProps({
	cardItems: {
		type: Array<any>,
		default: undefined,
		validator: (_, props) => !!props.cardItems || !!props.subscriptionItems,
	},
	subscriptionItems: {
		type: Array<any>,
		default: undefined,
		validator: (_, props) => !!props.cardItems || !!props.subscriptionItems,
	},
});

const store = useStore();

const isVatExempt = computed(() => store.getters["shoppingCart/isVatExempt"]);
const eligibleMemberPrice = computed(() => store.getters["shoppingCart/eligibleMemberPrice"]);

const hasMemberPrice = computed<boolean>(
	() => cardItems?.some((item) => !!item?.metadata?.prices?.withVat?.fullPrice?.memberPrice) || false,
);
const memberPrice = computed<number | undefined>(() =>
	hasMemberPrice.value ? eligibleMemberPrice?.value?.memberPrice : undefined,
);
const memberPriceText = computed<string | undefined>(() =>
	memberPrice.value ? `Pris for Telenor-kunder: ${formatPrice.oneTime(memberPrice.value, { round: true })}` : undefined,
);

const totalDiscount = computed<number>(() => {
	if (!cardItems) return 0;

	let totalDiscount = 0;
	cardItems.forEach((item) => {
		if (!item.metadata?.prices?.withVat?.fullPrice?.salePrice) return;
		const prices = isVatExempt.value
			? item.metadata.prices.withoutVat.fullPrice
			: item.metadata.prices.withVat.fullPrice;
		totalDiscount += prices.price - (prices?.memberPrice || prices.salePrice);
	});
	return totalDiscount;
});
const discountText = computed<string | undefined>(() =>
	totalDiscount.value > 0 ? `Spar ${formatPrice.oneTime(totalDiscount.value, { round: true })}` : undefined,
);

const bannerText = computed<string | undefined>(() => memberPriceText.value || discountText.value || undefined);
</script>

<style lang="scss" scoped>
.shopping-cart-card {
	margin: $spacing-l 0;
}

.shopping-cart-card-main {
	margin-bottom: (-$spacing-l);
	width: stretch;
}

.shopping-cart-card-main > * {
	margin-bottom: $spacing-l;
}

.shopping-cart-card-banner {
	width: stretch;
	height: 52px;
	display: flex;
	align-items: center;
	background-color: $color-primary-superlight;
	margin: $spacing-m (-$spacing-m) (-$spacing-m) (-$spacing-m);
	border-radius: 0 0 $spacing-s $spacing-s;

	h3 {
		margin-left: $spacing-m;

		@include font-text-bold-xs;
	}
}
</style>
