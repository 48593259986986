<template>
	<div class="shopping-cart-summary">
		<h2>Din handlekurv</h2>

		<SelectOrShowTradeIn />

		<ShoppingCartCard
			v-for="(item, i) in shoppingCartItems"
			:key="i"
			:card-items="item.value"
			:type="item.type"
		/>
		<ShoppingCartCard
			v-if="subscriptionInCart"
			:subscriptionItems="subscriptionItems"
		/>
		<!--<ECommerceRecommendedAccessory shoppingCartComponent/>-->
		<PaymentSummary showSubItems />
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { ProductCategory } from "~/components/ecommerce/types/ProductCategory";
export default defineNuxtComponent({
	name: "ShoppingCartSummary",
	props: ["heading"],

	computed: {
		...mapState("shoppingCart", ["loading"]),
		...mapGetters("shoppingCart", ["visibleCartItems"]),
		unprocessedItems() {
			return this.visibleCartItems?.filter((item) => !item?.metadata?.processed);
		},
		shoppingCartItems() {
			return [
				{ type: "mainItem", value: [{ ...this.mainItem, mainItem: true }] },
				{
					type: "hardwareItems",
					value:
						this.mainItem?.subItems?.filter((item) => item.type === "hardware") ||
						this.visibleCartItems?.filter((item) => item.type === "hardware") ||
						undefined,
				},
				{
					type: "agreementItems",
					value: this.mainItem?.subItems?.filter((item) => item.type === "agreement") || undefined,
				},
			].filter((item) =>
				item.type === "swapDevice"
					? item.value !== undefined
					: item.type === "mainItem"
						? item.value?.[0]?.metadata !== undefined
						: item.value?.length > 0,
			);
		},
		mainItem() {
			return this.visibleCartItems.filter(
				(x) =>
					x.type !== "old-swap-device" &&
					![ProductCategory.SOUND, ProductCategory.ACCESSORY, ProductCategory.ROUTER].includes(x.metadata?.category),
			)?.[0];
		},
		hardwareItems() {
			return this.visibleCartItems[0]?.subItems?.filter((item) => item.type === "hardware");
		},
		subscriptionItems() {
			return this.visibleCartItems[0]?.subItems?.filter(
				(item) => item.type === "subscription" || item.type === "msisdn",
			);
		},
		subscriptionInCart() {
			return typeof this.subscriptionItems === "object" && this.subscriptionItems.length > 0;
		},
		agreementItems() {
			return this.visibleCartItems[0]?.subItems?.filter((item) => item.type === "agreement");
		},
	},
});
</script>

<style lang="scss" scoped>
.shopping-cart-summary {
	padding: 0 1px;
	margin-bottom: -$spacing-m;
	color: $color-text;

	@include font-text-xs;

	@include breakpoint(mobile) {
		margin-top: -$spacing-l;
	}
}

h2 {
	@include font-title-s;
}
</style>
